export const arrowBottomDocs = (
    <svg
        width="10"
        height="8"
        viewBox="0 0 10 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5 0.666858L0 5.66686L1.66745 7.33313L5 3.99941L8.33255 7.33313L10 5.66686L5 0.666858Z"
            fill="#B4BBCB"
        />
    </svg>
);
