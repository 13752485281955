export const openEmail = (
    <svg
        width="64"
        height="64"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26 38L12 30V6C12 4.4087 12.6321 2.88258 13.7574 1.75736C14.8826 0.632141 16.4087 0 18 0L46 0C47.5913 0 49.1174 0.632141 50.2426 1.75736C51.3679 2.88258 52 4.4087 52 6V30L38 38L32 35L26 38ZM4.236 14.54L8 12.532V27.544L4.00156e-08 22.852V21.6C-0.000144987 20.1498 0.393923 18.7268 1.14006 17.4832C1.88619 16.2397 2.95632 15.2223 4.236 14.54ZM64 22.852L56 27.544V12.532L59.764 14.54C61.0437 15.2223 62.1138 16.2397 62.8599 17.4832C63.6061 18.7268 64.0001 20.1498 64 21.6V22.852ZM64 27.492L41.228 40.84L64 53.488V27.488V27.492ZM32 40.288L63.764 57.936C63.332 59.6681 62.333 61.206 60.9261 62.3048C59.5192 63.4037 57.7852 64.0004 56 64H8C6.21482 64.0004 4.48079 63.4037 3.07388 62.3048C1.66697 61.206 0.668015 59.6681 0.236 57.936L32 40.288ZM4.00156e-08 53.488L22.772 40.84L4.00156e-08 27.492V53.492V53.488Z"
            fill="url(#paint0_linear_3669_38222)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_3669_38222"
                x1="0"
                y1="0"
                x2="64"
                y2="-6.67141e-09"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#3D79F2" />
                <stop offset="1" stopColor="#5589F2" />
            </linearGradient>
        </defs>
    </svg>
);
